













import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({
  name: "PeriodSectionCard",
  components: {},
})
export default class extends Vue {
  @Prop({ required: true, default: "job" })
  public readonly type!: "job" | "ed" | "cert";

  @Prop({ required: true, default: "" })
  public readonly title!: string;

  @Prop({ required: false, default: "" })
  public readonly period!: string;

  @Prop({ required: false, default: "" })
  public readonly subtitle!: string;

  getIcon() {
    switch (this.type) {
      case "job":
        return require("@/assets/icons/info-card/job.svg");
      case "ed":
        return require("@/assets/icons/info-card/ed.svg");
      case "cert":
        return require("@/assets/icons/info-card/cert.svg");
      default:
        return "";
    }
  }
}
