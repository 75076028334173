













import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import ExpandingList from "@/components/lists/ExpandingList/index.vue";
import PeriodSectionCard from "./PeriodSectionCard.vue";

@Component({
  name: "PeriodSectionList",
  components: {
    ExpandingList,
    PeriodSectionCard,
  },
})
export default class extends Vue {
  @Prop({ required: true, default: [] })
  public readonly items!: unknown[];

  @Prop({ required: true, default: "job" })
  public readonly type!: "job" | "ed" | "cert";

  @Prop({ required: false, default: "" })
  public readonly title!: string;
}
