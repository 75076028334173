


























import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({
  name: "ExpandingList",
  components: {},
})
export default class extends Vue {
  @Prop({ required: false, default: "" })
  public readonly title!: string;

  @Prop({ required: false, default: 1 })
  public readonly previewCount!: number;

  private showMore = false;

  private collapsedHeight = 0;

  private expandedHeight = 0;

  mounted() {
    if (this.$slots.default && this.$slots.default.length > 0) {
      const previewCount =
        this.previewCount > this.$slots.default.length
          ? this.$slots.default.length
          : this.previewCount;

      this.$slots.default.forEach((slot, index) => {
        if (index < previewCount) {
          this.collapsedHeight += (slot.elm as HTMLElement).clientHeight;
        }
        this.expandedHeight += (slot.elm as HTMLElement).clientHeight;
      });
    }
  }
}
