























import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import { CandidateListItemModel } from "@/models/common";
import GridList from "@/components/lists/GridList/index.vue";
import CandidateCard from "./CandidateCard.vue";

@Component({
  name: "CandidatesList",
  components: {
    GridList,
    CandidateCard,
  },
})
export default class extends Vue {
  @Prop({ required: true, default: [] })
  public readonly items!: CandidateListItemModel[];

  @Prop({ required: false, default: "" })
  public readonly title!: string;

  @Prop({ required: false, default: false })
  public readonly loading!: boolean;

  @Prop({ required: false, default: false })
  public readonly initLoading!: boolean;

  @Prop({ required: false, default: true })
  public readonly showMore!: boolean;

  @Emit()
  more() {}
}
